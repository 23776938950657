import {
  Alert,
  ButtonPrimary,
  ContentHeader,
  ContentScrolling,
  ContentWrapper,
  FlexRow,
  Snackbar
} from '@papertrail/styleguide'
import React, { useEffect, useState } from 'react'
import { ImportData } from './ImportData'
import ImportList from './ImportList'
import { useParams } from 'react-router-dom'
import { useApiGet } from '@papertrail/web3-utils'
import { useLocation } from 'react-router-dom'

type Params = {
  accountid: string
}

const ImportTool = () => {
  const [importing, setImporting] = useState(null)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [importState, setImportState] = useState(null)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const importIdParam = queryParams.get('importid')
  const [importId, setImportId] = useState(importIdParam || null)
  const { accountid } = useParams() as Params
  const [reloadedImport, getImport] = useApiGet<any>(`/accounts/${accountid}/data_import/${importId}/rows`, (data) => {
    const myImport = {
      data: data.data,
      meta: data.meta
    }
    return myImport
  })
  const [ImportDetails, getImportDetails] = useApiGet<any>(`/accounts/${accountid}/data_import/${importId}`, (data) => {
    const myImport = {
      id: data.data.id,
      name: data.data.file_name,
      recordCount: data.data.row_count,
      status: data.data.state,
      mapping: data.data.mapping
    }
    return myImport
  })
  const [mapping, setMapping] = useState(null)
  const [pagination, setPagination] = useState({ page: 0, pageSize: 50 })
  const [errors, setErrors] = useState(false)

  const createImport = (): void => {
    setImporting(true)
  }

  const loaded = (): void => {
    reset()
    setShowSnackbar(true)
  }

  const exit = () => {
    reset()
  }

  const reset = () => {
    setImporting(false)
    setImportState(null)
    setImportId(null)
    setMapping(null)
    setErrors(false)
  }

  useEffect(() => {
    if (importIdParam) {
      setImportId(importIdParam)
    } else {
      setImporting(false)
    }
  }, [importIdParam])

  useEffect(() => {
    if (importState && importState.id) {
      setImporting(true)
    }
  }, [importState])

  useEffect(() => {
    if (importId) {
      getMyImport()
    }
  }, [importId])

  const getMyImport = () => {
    getImportDetails({})
    getImport({ limit: pagination && pagination.pageSize, page: pagination && pagination.page + 1 })
  }

  useEffect(() => {
    if (pagination && importId && !reloadedImport.isLoading) {
      getImport({ limit: pagination.pageSize, page: pagination.page + 1, errorsOnly: errors })
    }
  }, [pagination])

  useEffect(() => {
    if (reloadedImport.data && ImportDetails.data) {
      editImport(reloadedImport.data, ImportDetails.data)
      setMapping(ImportDetails.data.mapping)
    }
  }, [reloadedImport.isLoaded, ImportDetails.isLoaded])

  const editImport = (data, details) => {
    setImportState({
      data: data.data,
      meta: data.meta,
      id: details.id,
      name: details.name,
      recordCount: data.meta.total,
      status: details.status
    })
  }

  const openImport = (data) => {
    setImportId(data.id)
  }

  const setErrorsOnly = (e) => {
    setErrors(e)
    getImportDetails({})
    getImport({ limit: pagination.pageSize, page: 1, errorsOnly: e })
  }

  return (
    <ContentWrapper>
      <ContentHeader>
        Import Records
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setShowSnackbar(false)
          }}>
          <Alert data-testid="success-message" severity="success">
            Your import is underway. Please be patient, as the duration of this process may vary depending on the number
            of records included. Feel free to check back here periodically for progress updates!
          </Alert>
        </Snackbar>
      </ContentHeader>

      <ContentScrolling>
        {!importing && (
          <FlexRow justifyContent={'space-between'} alignItems="center" sx={{ marginBottom: '36px' }}>
            <h4>Import your asset data into your account</h4>
            <ButtonPrimary onClick={() => createImport()}>Get Started</ButtonPrimary>
          </FlexRow>
        )}

        {importing === true && (
          <ImportData
            pagination={pagination}
            Import={importState}
            loaded={loaded}
            exit={exit}
            setPagination={setPagination}
            setErrorsOnly={setErrorsOnly}
            refreshGet={getMyImport}
            onEdit={openImport}
            mapping={mapping ? mapping : null}></ImportData>
        )}
        {importing === false && <ImportList reRender={importing === false} onEdit={openImport}></ImportList>}
      </ContentScrolling>
    </ContentWrapper>
  )
}

export default ImportTool
