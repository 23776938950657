export const FindFolderIdByPath = (accountName, path, folderTree) => {
  if (path.includes('root')) {
    return null
  }
  const normalizedPath = path.startsWith('/') ? path : `/${path}`

  const pathArray = normalizedPath.split('/').filter((part) => part !== '')
  pathArray.unshift(accountName)

  const findFolderRecursively = (node, pathArray) => {
    if (!node || !pathArray || pathArray.length === 0) {
      return null
    }

    if (node.name === pathArray[0] || node.name === `${pathArray[0]}/${pathArray[1]}`) {
      if (pathArray.length === 1 || (pathArray.length === 2 && node.name === `${pathArray[0]}/${pathArray[1]}`)) {
        return node.id
      } else if (node.children && node.children.length > 0) {
        return findFolderRecursively(
          node.children.find(
            (child) =>
              child.name === pathArray[1] || (pathArray.length > 2 && child.name === `${pathArray[1]}/${pathArray[2]}`)
          ),
          pathArray.slice(node.name === pathArray[0] ? 1 : 2)
        )
      }
    }

    return null
  }

  return findFolderRecursively(folderTree, pathArray)
}

export const constructFolderPathById = (folderTree, folderId) => {
  function findFolderRecursively(node, targetId, currentPath) {
    if (node.id === targetId) {
      return currentPath
    } else if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        const path = findFolderRecursively(child, targetId, currentPath + '/' + child.name)
        if (path) {
          return path
        }
      }
    }
    return null
  }

  const path = findFolderRecursively(folderTree, folderId, '')
  return path ? path : folderId
}
