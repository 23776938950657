import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import Popover from '@mui/material/Popover'
import { FolderInfo } from './hooks'
import { FlexRow, FolderIcon, ToolTip, useGridApiContext } from '@papertrail/styleguide'
import { FindFolderIdByPath, constructFolderPathById } from './FolderHelper'

type Props = {
  folders: FolderInfo[]
  account
  gridProps
}

interface RenderTree {
  id: string
  name: string
  children?: readonly RenderTree[]
}

const RichObjectTreeView = (props: Props) => {
  const { folders, account, gridProps } = props

  const data: RenderTree = {
    id: 'root',
    name: account.name,
    children: folders
  }

  const path = `${gridProps.value}`
  const [folderId, setFolderId] = useState(FindFolderIdByPath(account.name, path, data))

  const apiRef = useGridApiContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const renderTree = (nodes: RenderTree) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} classes={{ label: 'custom-label' }}>
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  )

  const onNodeSelected = async (event: React.SyntheticEvent, nodeIds: Array<any> | string) => {
    const clickedElement = event.target as HTMLElement
    const isLabelClicked = clickedElement.getAttribute('class').includes('MuiTreeItem-label')
    if (isLabelClicked) {
      handlePopoverClose()
      const { field, id } = gridProps
      const folderPath = constructFolderPathById(data, nodeIds)
      setFolderId(nodeIds)
      apiRef.current.setEditCellValue({
        id,
        field,
        value: folderPath
      })
    }
  }

  return (
    <>
      <div onClick={handlePopoverOpen} aria-describedby={id}>
        <FlexRow justifyContent="space-between">
          <Box
            flex={0.8}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingLeft: '16px',
              width: '80px'
            }}>
            <ToolTip title={folderId && gridProps.value}>
              <div>{folderId ? gridProps.value : 'Select Folder'}</div>
            </ToolTip>
          </Box>
          <Box flex={0.2}>
            <FolderIcon color="primary" />
          </Box>
        </FlexRow>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Box sx={{ p: '8px' }}>
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={folderId ? ['root', folderId] : ['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={(event: React.SyntheticEvent, nodeIds: Array<any> | string) => onNodeSelected(event, nodeIds)}
            defaultSelected={folderId || null}
            className={'custom-label'}>
            {renderTree(data)}
          </TreeView>
        </Box>
      </Popover>
    </>
  )
}

export default RichObjectTreeView
